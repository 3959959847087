//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/layout.js
// Description: Global page layout.
// Created:     FUB, Oct 12, 2021
//==============================================================================

import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import "@fontsource/open-sans" // Defaults to weight 400.

import { theme } from "./theme"
import { Header } from "./header"
import { Footer } from "./footer"
import { Container } from "./primitives"

//==============================================================================
/*const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body, html {
    font-family: ${props => props.theme.fonts.main};
    height: 100%;
    background-color: ${props => props.theme.colors.bgWindow};
  }
`*/

// global typography taken from https://type-scale.com/
// with the following settings:
// - Open Sans 600
// - body font same as headings
// - body weight 400
// - 16px base size
// - 1.125 scale for better readability on mobile
// also see https://www.gridlover.net/
// and https://www.hongkiat.com/blog/web-typography-tools/
const GlobalStyle = createGlobalStyle`
html {font-size: 100%;} /*16px*/

body {
  background: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 1.802rem;
}

h2 {font-size: 1.602rem;}

h3 {font-size: 1.424rem;}

h4 {font-size: 1.266rem;}

h5 {font-size: 1.125rem;}

small, .text_small {font-size: 0.889rem;}

/*----- FUB overrides -----*/
body {
  margin: 0;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.bgWindow};
}
h1 {
  margin-bottom: 0;
}
h2, p {&:first-child {
  margin-top: 0;
}}

`

//------------------------------------------------------------------------------
const PageCanvas = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: ${props => props.theme.dimensions.maxWidth};
  margin-left: auto;
  margin-right: auto;
  background-color: ${props => props.theme.colors.bgCanvas};
  overflow: hidden;
`

const StyledHeader = styled(Header)`
  flex-grow: 0;
`

const StyledMain = styled.main`
  flex-grow: 1;
`

const StyledFooter = styled(Footer)`
  flex-grow: 0;
  margin-top: 50px;
`

//==============================================================================
export const Layout = ({ title, children }) => {
  /*const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  */

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <PageCanvas>
        <StyledHeader title={title || "Title Not Set"} />

        <StyledMain>
          <Container>
            {children}
          </Container>
        </StyledMain>

        <StyledFooter />
      </PageCanvas>

    </ThemeProvider>
  )
}

//==============================================================================
// end of file
