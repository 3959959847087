//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/footer.js
// Description: Global page footer.
// Created:     FUB, Oct 12, 2021
//==============================================================================

import React from "react"
import styled from "styled-components"

import { useLocation } from "../utils/location"
import { Container, A } from "./primitives"

//==============================================================================
const AccentColorDiv = styled.div`
    background-color: ${props =>
        (props.path.startsWith("/felix/") && props.theme.colors.accent.felix[0]) ||
        (props.path.startsWith("/uwe/") && props.theme.colors.accent.uwe[0]) ||
        (props.path.startsWith("/birgit/") && props.theme.colors.accent.birgit[0]) ||
        (props.path.startsWith("/tom/") && props.theme.colors.accent.tom[0]) ||
        props.theme.colors.accent.all[0]
    };
    color: ${props =>
        (props.path.startsWith("/felix/") && props.theme.colors.accent.felix[1]) ||
        (props.path.startsWith("/uwe/") && props.theme.colors.accent.uwe[1]) ||
        (props.path.startsWith("/birgit/") && props.theme.colors.accent.birgit[1]) ||
        (props.path.startsWith("/tom/") && props.theme.colors.accent.tom[1]) ||
        props.theme.colors.accent.all[1]
    };
`

//------------------------------------------------------------------------------
const FooterRow = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

//==============================================================================
export const Footer = ({ className }) => {
    const { path } = useLocation()

    const copyright = (path.startsWith("/felix/") && "Felix Bertram") ||
        (path.startsWith("/uwe/") && "Uwe Bertram") ||
        (path.startsWith("/birgit/") && "Birgit Bertram") ||
        (path.startsWith("/tom/") && "Tom Bertram") ||
        "Felix Bertram"

    return (
        <footer className={className}>
            <AccentColorDiv path={path}>
                <FooterRow>
                    <div>
                        Copyright © {new Date().getFullYear()} {copyright}
                    </div>
                    <div>
                        <A href="/felix/imprint" accentBackground={true}>
                            Imprint
                        </A>
                    </div>
                </FooterRow>
            </AccentColorDiv>
        </footer>
    )
}

//==============================================================================
// end of file
