//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/theme.js
// Description: Global theme.
// Created:     FUB, Oct 12, 2021
//==============================================================================

export const theme = {
    fonts: {
        main: "Open Sans",
        //main: "Muli, sans-serif",
        //code: "Roboto Mono, monospace",
    },
    colors: {
        bgWindow: "hsl(207, 8%, 95%)",
        bgCanvas: "hsl(207, 8%, 98%)",
        text: "hsl(207, 8%, 10%)",
        accent: {
            //all: ["grey", "white"],
            all: ["#212529", "white"],
            //felix: ["blue", "white"],
            felix: ["#1a3ea2", "white"],
            //uwe: ["green", "white"],
            uwe: ["#1e6933", "white"],
            //tom: ["orange", "black"],
            tom: ["#f29240", "black"],
            //birgit: ["purple", "white"],
            birgit: ["#743bac", "white"],
        }
    },
    breakpoints: {
        mobile: "only screen and (max-width: 50rem)",
        tablet: "only screen and (max-width: 65rem)",
    },
    dimensions: {
        //maxWidth: "1140px",
        maxWidth: "800px",
    },
    spacings: {
        normal: "16px",
        negNormal: "-16px",
        /*xxSmall: ".25rem",
        xSmall: ".5rem",
        small: "1rem",
        medium: "2rem",
        large: "3rem",*/
    },
    shadows: {
        shadow1: "0px 5px 20px rgba(30, 30, 31, 0.05)",
    },
}

//==============================================================================
// end of file
