//==============================================================================
//==============================================================================

import React from "react"
import { MDXProvider } from "@mdx-js/react"

import { Layout } from "./layout"
import { Seo } from "./seo"
import {H1, H2, H3, P, Ul, Ol, Li, Table, Tr, Td, Th, A} from "./primitives"

//------------------------------------------------------------------------------
// component mapping

const shortcodes = {
    h1: (props) => <H1 {...props} />,
    h2: (props) => <H2 {...props} />,
    h3: (props) => <H3 {...props} />,
    // ...
    // h6
    p: (props) => <P {...props} />,
    // thematicBreak
    // blockquote
    ul: (props) => <Ul {...props} />,
    ol: (props) => <Ol {...props} />,
    li: (props) => <Li {...props} />,
    table: (props) => <Table {...props} />,
    tr: (props) => <Tr {...props} />,
    td: (props) => <Td {...props} />,
    th: (props) => <Th {...props} />,
    // pre
    // code
    // em
    // strong
    // delete
    // code
    // hr
    a: A,
    //img,
}

//------------------------------------------------------------------------------
// page layout

export const LayoutMdx = ({ children, pageContext }) => {
    const {
        title,
        seoTitle
    } = pageContext.frontmatter

    return (
        <Layout title={title}>
            <Seo title={seoTitle ?? title} />

            <MDXProvider components={shortcodes}>
                {children}
            </MDXProvider>
        </Layout>
    )
}
export default LayoutMdx

//==============================================================================
 // end of file
