//==============================================================================
// Project:     www.Bertram-Family.com
// Name:        components/header.js
// Description: Global page header.
// Created:     FUB, Oct 12, 2021
//==============================================================================

import React from "react"
import styled from "styled-components"

import { useLocation } from "../utils/location"
import { Container, H1, A } from "./primitives"

//==============================================================================
const AccentColorDiv = styled.div`
  background-color: ${props =>
    (props.path.startsWith("/felix/") && props.theme.colors.accent.felix[0]) ||
    (props.path.startsWith("/uwe/") && props.theme.colors.accent.uwe[0]) ||
    (props.path.startsWith("/birgit/") && props.theme.colors.accent.birgit[0]) ||
    (props.path.startsWith("/tom/") && props.theme.colors.accent.tom[0]) ||
    props.theme.colors.accent.all[0]
  };
  color: ${props =>
    (props.path.startsWith("/felix/") && props.theme.colors.accent.felix[1]) ||
    (props.path.startsWith("/uwe/") && props.theme.colors.accent.uwe[1]) ||
    (props.path.startsWith("/birgit/") && props.theme.colors.accent.birgit[1]) ||
    (props.path.startsWith("/tom/") && props.theme.colors.accent.tom[1]) ||
    props.theme.colors.accent.all[1]
  };
`

const MenuRowFelix = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

//==============================================================================
const HeaderFelix = ({ title, path, className }) => {
  return (
    <header className={className}>
      <MenuRowFelix>
        <A href="/felix/">Home</A>
        <A href="/felix/projects/">Projects</A>
        <A href="/felix/resume/">Resume</A>
      </MenuRowFelix>
      <AccentColorDiv path={path}>
        <Container>
          <H1>{title}</H1>
        </Container>
      </AccentColorDiv>
    </header>
  )
}

//------------------------------------------------------------------------------
const HeaderCommon = ({ title, path, className }) => {
  return (
    <header className={className}>
      <AccentColorDiv path={path}>
        <Container>
          <H1>{title}</H1>
        </Container>
      </AccentColorDiv>
    </header>
  )
}

//------------------------------------------------------------------------------
export const Header = ({ title, className }) => {
  const { path } = useLocation()

  return path.startsWith("/felix/") ?
    <HeaderFelix title={title} path={path} className={className} /> :
    <HeaderCommon title={title} path={path} className={className} />
}

//==============================================================================
// end of file
